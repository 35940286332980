window.oTable="";
window.xhr = () => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));

$(document).on("change", "#context-selector", function() {
  $(this).closest('form').trigger('submit');
  return true;
});

$(document).on('click', '.best_in_place_editable', function() {
  let clickElm = $(this).find('.best_in_place');
  if (clickElm.length > 0) {
    clickElm.trigger('click');
  }
});

$(document).on('click', '.best_in_place', e => e.stopPropagation());

$(document).on('change', '.provider_network_selection', function(e){
  let isSnap = $(this).find("option:selected").attr("is_snap");
  if (isSnap === 'true') {
    return $(".group_number_input, .member_number_input").attr("data-validation-optional", "true");
  } else {
    return $(".group_number_input, .member_number_input").removeAttr("data-validation-optional");
  }
});

window.checker_field = function() {
  // If we find any chgecker fields then we need to compute the check sum field and save it.
  if ($(".checker_field")) {
    let result = atob($($(".checker_field")[0]).val()).
    split(":").
    map(Number).
    reduce((a, b) => a + b);
    return $(".check_sum").attr("value",result);
  }
};


window.editor_config = {
  toolbarGroups:
    [
      {
        name: 'document',
        groups: [
          'mode',
          'document',
          'doctools',
        ]
      },
      {
        name: 'clipboard',
        groups: [
          'clipboard',
          'undo',
        ]
      },
      {
        name: 'editing',
        groups: [
          'find',
          'selection',
          'spellchecker',
          'editing',
        ]
      },
      {
        name: 'forms',
        groups: ['forms']
      },
      {
        name: 'basicstyles',
        groups: [
          'basicstyles',
          'cleanup',
        ]
      },
      {
        name: 'paragraph',
        groups: [
          'list',
          'indent',
          'blocks',
          'align',
          'bidi',
          'paragraph',
        ]
      },
      {
        name: 'links',
        groups: ['links']
      },
      {
        name: 'insert',
        groups: ['insert']
      },
      {
        name: 'styles',
        groups: ['styles']
      },
      {
        name: 'colors',
        groups: ['colors']
      },
      {
        name: 'tools',
        groups: ['tools']
      },
      {
        name: 'others',
        groups: ['others']
      },
      {
        name: 'about',
        groups: ['about']
      }
    ],
  allowedContent: true,
  removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteFromWord,Scayt,Button,ImageButton,Flash,Smiley,SpecialChar,PageBreak,Maximize,About',
  extraPlugins: 'tableresize,tabletools'
};

$.extend(true, $.fn.dataTable.defaults, {
  language: {
    search: '_INPUT_',
    searchPlaceholder: 'Search Entries'
  },
  'drawCallback'(settings) {
    let contentObj = $('table.data-table').parents('.maincontent');
    if (settings._iDisplayLength >= settings.fnRecordsDisplay()) {
      $(settings.nTableWrapper).find('.dataTables_paginate').hide();
    } else {
      $(settings.nTableWrapper).find('.dataTables_paginate').show();
    }
    if ($('.dataTable .dt_checkbox').length > 1) {
      $('.task-group-item').css('visibility', 'visible');
    } else {
      $('.task-group-item').css('visibility', 'hidden');
    }
    if (contentObj.find('.no-data-message').length > 0) {
      if ($('table.data-table tbody:first tr[role=\'row\']').length > 0) {
        contentObj.find('.no-data-message').addClass('hide');
        contentObj.find('.data-table-wrap').removeClass('hide');
      } else {
        contentObj.find('.no-data-message').removeClass('hide');
        contentObj.find('.data-table-wrap').addClass('hide');
      }
    }
    $('#selectall').prop('checked', false);
    if ($('.actionSection').length > 0) {
      $('.dataTables_filter').html($('.actionSection').html());
    }
    if ($('table.data-table').attr('zerorecords') !== '') {
      $('.dataTables_empty').html($('table.data-table').attr('zerorecords'));
    }
  }
}
);

$.extend($.fn.dataTableExt.oStdClasses,
  {'sFilterInput': 'form-control'});

$(document).on('ajax:beforeSend', function(event, xhr, settings) {
  NProgress.start();
});

$(document).on('ajax:success', function(event, xhr, settings) {
  NProgress.done();
});

$(document).on('ajax:error', function(event, xhr, settings) {
  NProgress.done();
});

$(document).ajaxStop(function() {
  NProgress.done();
});

$(document).on('preXhr.dt', function(e, settings, data) {
    NProgress.start();
  }).on('xhr.dt', function(e, settings, json, xhr) {
    NProgress.done();
  }).on('error.dt', function(e, settings, techNote, message) {
    NProgress.done();
    console.log('Sorry we are not able to process your request this time!');
});

$(document).ready(function() {
  $('.alert-dismissible, .alert-dismissable').delay(5000).fadeOut('slow');
});

// namespace for #simple_form_modal
this.simpleFormModal = {
  // handle enable/disable form buttons
  savingForm(saving, btnText, btnProcessingText) {
    if (btnText == null) { btnText = 'Saving'; }
    if (btnProcessingText == null) { btnProcessingText = 'Saving...'; }
    $('#modal_save_button', '#simple_form_modal').html(saving ? btnProcessingText : btnText);
    $('#modal_save_button', '#simple_form_modal').attr('disabled', saving);
    $('#modal_cancel_button', '#simple_form_modal').attr('disabled', saving);
  }
};